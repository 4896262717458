export const RECORD_ADDRESS = 'RECORD_ADDRESS'
export const ADD_CART = 'ADD_CART'
export const REDUCE_CART = 'REDUCE_CART'
export const INIT_BUYCART = 'INIT_BUYCART'
export const CLEAR_CART = 'CLEAR_CART'
export const RECORD_SHOPDETAIL = 'RECORD_SHOPDETAIL'
export const RECORD_USERINFO = 'RECORD_USERINFO'
export const GET_USERINFO = 'GET_USERINFO'
export const CONFIRM_REMARK = 'CONFIRM_REMARK'
export const CONFIRM_INVOICE = 'CONFIRM_INVOICE'
export const CHOOSE_SEARCH_ADDRESS = 'CHOOSE_SEARCH_ADDRESS'
export const SAVE_GEOHASH = 'SAVE_GEOHASH'
export const CONFIRM_ADDRESS = 'CONFIRM_ADDRESS'
export const CHOOSE_ADDRESS = 'CHOOSE_ADDRESS'
export const NEED_VALIDATION = 'NEED_VALIDATION'
export const SAVE_CART_ID_SIG = 'SAVE_CART_ID_SIG'
export const SAVE_ORDER_PARAM = 'SAVE_ORDER_PARAM'
export const CHANGE_ORDER_PARAM = 'CHANGE_ORDER_PARAM'
export const ORDER_SUCCESS = 'ORDER_SUCCESS'
export const SAVE_SHOPID = 'SAVE_SHOPID'
export const SAVE_ORDER = 'SAVE_ORDER'
export const OUT_LOGIN = 'OUT_LOGIN'
export const RETSET_NAME = 'RETSET_NAME'
export const SAVE_AVANDER = 'SAVE_AVANDER'
export const SAVE_ADDDETAIL = 'SAVE_ADDDETAIL'
export const SAVE_ADDRESS = 'SAVE_ADDRESS'
export const SAVE_QUESTION = 'SAVE_QUESTION'
export const ADD_ADDRESS = 'ADD_ADDRESS'
export const BUY_CART = 'BUY_CART'
