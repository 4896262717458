import {
	getAddressList
} from '../service/getData'
import {
	getUserInfo,
} from '../service'
import {
	GET_USERINFO,
	SAVE_ADDRESS
} from './mutation-types.js'

export default {

	async getUserInfo({
		commit,
		state
	}) {
		const sessionId = sessionStorage.getItem('xxl_sso_sessionid');
		let res = await getUserInfo({ sessionId: sessionId });
		const data = res.msg ? JSON.parse(res.msg) : {};
		const userInfo = { ...data, ...data.plugininfo };
		delete userInfo.plugininfo;
		commit(GET_USERINFO, userInfo)
		commit(SAVE_ADDRESS, data.plugininfo.addres);
	},
	async saveAddress({
		commit,
		state
	}) {

		if (state.removeAddress.length > 0) return;

		let addres = await getAddressList(state.userInfo.user_id);
		commit(SAVE_ADDRESS, addres);
	},
}