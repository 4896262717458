import axios from "axios";
import { Toast } from "vant";

export default async (
  requestUrl = "",
  params = {},
  method = "GET",
  loading = false
) => {
  return new Promise((resolve, reject) => {
    const ua = navigator.userAgent.toLowerCase();
    const isWeixin = ua.indexOf("micromessenger") != -1;
    if (typeof params !== "object") params = {};
    const sessionId = sessionStorage.getItem("xxl_sso_sessionid");
    let _option = params;
    _option = {
      method,
      url: requestUrl,
      baseURL: "/api",
      timeout: 30000,
      headers: {
        xxl_sso_sessionid: sessionId
      },
      withCredentials: true, //是否携带cookies发起请求
      validateStatus: status => {
        return status >= 200 && status < 300;
      },
      data:
        method == "POST"
          ? {
              ...params
            }
          : {},
      params:
        method == "GET" || !method
          ? {
              ...params
            }
          : {}
    };
    if (loading) {
      Toast.loading({
        message: "加载中...",
        forbidClick: true
      });
    }
    axios.request(_option).then(
      res => {
        Toast.clear();
        if (res.data.code == "000201") {
          reject(res.data);
          return false;
        }
        resolve(typeof res.data === "object" ? res.data : JSON.parse(res.data));
      },
      error => {
        Toast.clear();
        if (error.response) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      }
    );
  });
};
