<template>
    <div>
        <transition name="router-fade" mode="out-in">
            <keep-alive include="homePage, routePage, profilePage">
                <router-view></router-view>
            </keep-alive>
        </transition>
        <!-- <transition name="router-fade" mode="out-in">
            <router-view v-if="!$route.meta.keepAlive"></router-view>
        </transition> -->
        <svg-icon></svg-icon>
        <foot-guide v-if="$route.meta.tabbar"></foot-guide>
        <div
            v-if="$route.meta.tabbar"
            :class="['caring-model', caringStatus == 'standard' ? 'active' : '',]"
            @click="
                () =>
                    caringStatus == 'standard' ? openCaring() : closeCaring()
            "
        >
            {{ caringStatus == "standard" ? "关怀模式" : "取消关怀" }}
        </div>
    </div>
</template>

<script>
import { getWeixinJssdk } from "@/service";
import svgIcon from "./components/common/svg";
import footGuide from "@/components/footer/footGuide";
export default {
    components: {
        svgIcon,
        footGuide,
    },
    data() {
        return {
            caringStatus: "standard",
            fontSize: "",
        };
    },
    created() {
        const fontSize = document.documentElement.style.fontSize;
        this.fontSize = fontSize;
    },
    mounted() {
        const caringStatus = localStorage.getItem("caringStatus");

        if (caringStatus == "caring") {
            setTimeout(() => {
                this.openCaring();
            }, 500);
        }
    },
    methods: {
        openCaring() {
            this.caringStatus = "caring";
            const size = document.documentElement.style.fontSize;

            if (this.fontSize == size) {
                const fontSize =
                    document.documentElement.style.fontSize.replace("px", "");
                document.documentElement.style.fontSize =
                    Number(fontSize) * 1.2 + "px";
                localStorage.setItem("caringStatus", "caring");
            } else {
                this.caringStatus = "caring";
            }
        },
        closeCaring() {
            this.caringStatus = "standard";
            const size = document.documentElement.style.fontSize.replace(
                "px",
                ""
            );

            const initSize = this.fontSize.replace("px", "");
            if (Number(initSize) * 1.2 == Number(size)) {
                const fontSize =
                    document.documentElement.style.fontSize.replace("px", "");
                document.documentElement.style.fontSize =
                    Number(fontSize) / 1.2 + "px";
                localStorage.setItem("caringStatus", "standard");
            } else {
                this.caringStatus = "standard";
            }
        },
        async initWechat() {
            /*
             * 注意：
             * 1. 所有的JS接口只能在公众号绑定的域名下调用，公众号开发者需要先登录微信公众平台进入“公众号设置”的“功能设置”里填写“JS接口安全域名”。
             * 2. 如果发现在 Android 不能分享自定义内容，请到官网下载最新的包覆盖安装，Android 自定义分享接口需升级至 6.0.2.58 版本及以上。
             * 3. 常见问题及完整 JS-SDK 文档地址：http://mp.weixin.qq.com/wiki/7/aaa137b55fb2e0456bf8dd9148dd613f.html
             *
             * 开发中遇到问题详见文档“附录5-常见错误及解决办法”解决，如仍未能解决可通过以下渠道反馈：
             * 邮箱地址：weixin-open@qq.com
             * 邮件主题：【微信JS-SDK反馈】具体问题
             * 邮件内容说明：用简明的语言描述问题所在，并交代清楚遇到该问题的场景，可附上截屏图片，微信团队会尽快处理你的反馈。
             */

            const { nonceStr, jsapi_ticket, signature, timestamp } =
                await getWeixinJssdk({ url: window.location.href });
            if (nonceStr) {
                wx.config({
                    debug: false,
                    appId: "wx5a5c6ad0371692ec",
                    timestamp: timestamp,
                    nonceStr: nonceStr,
                    signature: signature,
                    jsApiList: ["checkJsApi", "openLocation", "getLocation"],
                });
            }
        },
    },
};
</script>

<style lang="scss">
@import "./style/common";
@import "@/style/mixin";

.router-fade-enter-active,
.router-fade-leave-active {
    transition: opacity 0.3s;
}
.router-fade-enter,
.router-fade-leave-active {
    opacity: 0;
}

.caring-model {
    position: absolute;
    top: 50px;
    right: 30px;
    // width: 100px;
    padding: 0 20px;
    height: 50px;
    font-size: 26px;
    color: #fff;
    background: rgb(170, 170, 170);
    // border: 2px solid #fff;
    z-index: 10;
    text-align: center;
    line-height: 50px;
    border-radius: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    flex-direction: row;
    line-height: normal;
    &.active {
        background: #b72e32;
    }
}
</style>
