import Vue from "vue";
import VueRouter from "vue-router";
import {
  Button,
  Icon,
  Col,
  Row,
  Divider,
  Tab,
  Tabs,
  Toast,
  Popup,
  Field,
  Picker,
  Uploader,
  Image as VanImage,
  DropdownMenu,
  DropdownItem,
  Tabbar,
  Empty,
  CountDown,
  TabbarItem,
  Cell,
  CellGroup
} from "vant";
import VueWechatTitle from "vue-wechat-title";
import routes from "./router/router";
import store from "./store/";
import wxsdk from "./config/wxsdk";
import { isIOS, requestWxStr } from "./config/mUtils";
import { routerMode } from "./config/env";
import "./config/rem";

import App from "./App.vue";

const ua = navigator.userAgent.toLowerCase();
Vue.prototype.$isWeixin = ua.indexOf("micromessenger") != -1;

Vue.config.productionTip = false;
Vue.prototype.$wxsdk = wxsdk;
Vue.use(VueRouter);

Vue.use(Button)
  .use(Icon)
  .use(Col)
  .use(Row)
  .use(Divider)
  .use(Tabs)
  .use(Tab)
  .use(Empty)
  .use(Field)
  .use(Popup)
  .use(Toast)
  .use(Cell)
  .use(CellGroup)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Picker)
  .use(CountDown)
  .use(VanImage)
  .use(Uploader)
  .use(DropdownMenu)
  .use(DropdownItem);
Vue.use(VueWechatTitle);

const router = new VueRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  routes, // `routes: routes` 的缩写
  mode: routerMode
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     if (from.meta.keepAlive) {
  //       from.meta.savedPosition = document.body.scrollTop;
  //     }
  //     return { x: 0, y: to.meta.savedPosition || 0 };
  //   }
  // }
});

router.beforeEach((to, from, next) => {
  // beforeEach是router的钩子函数，在进入路由前执行
  /* vue-router用的history模式，在微信签名验证的时候iOS验证URL第一次进来的URL */
  console.log(to, from, "from");
  if (isIOS()) {
    if (from.path === "/home" || from.path === "/") {
      requestWxStr(); //该函数和之前一样，被单独提取出来了
    }
  }

  if (to.meta.title) {
    // 判断是否有标题
    document.title = to.meta.title;
  }
  next(); // 执行进入路由，如果不写就不会进入目标页
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
