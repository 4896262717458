import fetch from '../config/fetch'
import { getStore } from '../config/mUtils'

/**
 * 获取首页主接口
 */

export const getHomeList = () => fetch('/app/mainIndex', {
	type: 'group'
}, 'POST');

/**
 * 获取首页主接口
 */

export const getPageIndex = () => fetch('/app/pageIndex', {
}, 'POST');

/**
 * 人文黄埔
 */

export const getHumanisticHuangpu = (params) => fetch('/others/humanisticHuangpu', {
	...params
}, 'POST');

/**
 * 获取短信验证码
 */

export const mobileCode = phone => fetch('/v4/mobile/verify_code/send', {
	mobile: phone,
	scene: 'login',
	type: 'sms'
}, 'POST');

/**
 * 获取活动查询列表
 */

export const getActivityList = (params) => fetch('/activity/list', {
	...params
}, 'POST', true);


/**
 * 获取推荐点位
 */

export const getLinePoint = (params) => fetch('/line/point', {
	...params
}, 'POST');

// /line/humanisticHuangpu
/**
 * 获取推荐路线
 */

export const getLineHumanisticList = (params) => fetch('/line/humanisticHuangpu', {
	...params
}, 'POST');

// /line/details
/**
 * 获取路线详情
 */

export const getLineDetails = (params) => fetch('/line/details', {
	...params
}, null, true);

// /activity/saveForUser
/**
 * 活动预约
 */

export const setActivitySave = (params) => fetch('/activity/saveForUser', {
	...params
}, 'POST', true);

// /activity/detailActivity
/**
 * 查询活动详情
 */

export const getDetailActivity = (params) => fetch('/activity/detailActivity', {
	...params
}, 'POST', true);

// /activity/reduceForUser
/**
 * 活动预约取消
 */

export const setActivityReduce = (params) => fetch('/activity/reduceForUser', {
	...params
}, 'POST', true);

// /activity/collection
/**
 * 活动收藏
 */

export const setActivityCollection = (params) => fetch('/activity/collection', {
	...params
}, 'POST', true);

// /activity/thumbsUp
/**
 * 活动点赞
 */

export const setThumbsUp = (params) => fetch('/activity/thumbsUp', {
	...params
}, 'POST', true);

// /activity/cancelThumbsUp
/**
 * 活动点赞取消
 */

export const setCancelThumbsUp = (params) => fetch('/activity/cancelThumbsUp', {
	...params
}, 'POST', true);

// /activity/cancelCollection
/**
 * 活动收藏取消
 */

export const setCancelCollection = (params) => fetch('/activity/cancelCollection', {
	...params
}, 'POST', true);


// /line/pointDetail
/**
 * 活动收藏
 */

export const getPointDetail = (params) => fetch('/line/pointDetail', {
	...params
}, 'POST', true);

// /line/punch
/**
 * 打卡
 */

export const setLinePunch = (params) => fetch('/line/punch', {
	...params
}, 'POST', true);

// /others/upload
/**
 * 上传
 */

export const setUpload = (params) => fetch('/others/upload', params, 'POST', 'fetch',
	{ 'Content-Type': 'multipart/form-data' });

// /line/comment
/**
 * 打卡
 */

export const setComment = (params) => fetch('/line/comment', params, 'POST', true);

//  /others/walkingDynamics
/**
 * 行走动态
 */

export const getwalkingList = (params) => fetch('/others/walkingDynamics', params, 'POST');


//  /others/humanisticHuangpu
/**
 * 人文黄埔
 */

export const getHumanisticList = (params) => fetch('/others/humanisticHuangpu', {
	...params
}, 'POST');

// /others/vedioHot
/**
 * 视频
 */

export const getVedioHotList = (params) => fetch('/others/vedioHot', {
	...params
}, 'POST');

// /user/mySetup
/**
 * 设置
 */

export const setUserSetup = (params) => fetch('/user/mySetup', {
	...params
}, 'POST', true);

// /app/logincheck
/**
 * 设置
 */

export const getUserInfo = (params) => fetch('/app/logincheck', {
	...params
});

// /user/myRoute
/**
 * 我的路线
 */

export const getMyRoute = (params) => fetch('/user/myRoute', {
	...params
}, "POST", true);

// /user/myCollect
/**
 * 我的收藏
 */

export const getMyCollect = (params) => fetch('/user/myCollect', {
	...params
}, "POST", true);

// /user/myPunch
/**
 * 我的打卡
 */

export const getMyPunch = (params) => fetch('/user/myPunch', {
	...params
}, "POST", true);

/**
 * 我的token
 */

export const loginCallback = (params, status = true) => fetch('/wechat/login/callback', {
	...params
}, null, status);

// /question/list
/**
 * 答题列表
 */

export const getQuestionList = (params) => fetch('/question/list', {
	...params
}, 'POST', true);

// /user/myComments
/**
 * 评论列表
 */

export const getMyComments = (params) => fetch('/user/myComments', {
	...params
}, 'POST', true);

// /wechat/login/weixin/jssdk
export const getWeixinJssdk = (params) => fetch('/wechat/login/weixin/jssdk', {
	...params
}, 'POST', false);

// /user/myPoint
export const getMyPoint = (params) => fetch('/user/myPoint', {
	...params
}, 'POST', true);

// 
export const saveForUser = (params) => fetch('/question/saveForUser', {
	...params
}, 'POST', true);