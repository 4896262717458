const openLocation = ({ latitude, longitude, name, address }) => {
  const lat = latitude;
  const long = longitude;
  wx.openLocation({
    latitude: Number(lat),
    longitude: Number(long),
    name: name,
    address: address,
    scale: 14,
    infoUrl: "http://weixin.qq.com"
  });
};

const getLocation = () => {
  return new Promise((resolve, reject) => {
    wx.getLocation({
      type: "gcj02",
      success: function(res) {
        resolve(res);
      },
      cancel: function(res) {
        reject("用户拒绝授权获取地理位置");
      }
    });
  });
};

export default { openLocation, getLocation };
