import App from '../App.vue'

const home = r => require.ensure([], () => r(require('../page/home/home')), 'home')
const route = r => require.ensure([], () => r(require('../page/route/route')), 'route')
const follow = r => require.ensure([], () => r(require('../page/follow/follow')), 'follow')
const point = r => require.ensure([], () => r(require('../page/point/point')), 'point')
const walk = r => require.ensure([], () => r(require('../page/walk/walk')), 'walk')
const walkDetails = r => require.ensure([], () => r(require('../page/walk/details')), 'walkDetails')
const humanity = r => require.ensure([], () => r(require('../page/humanity/humanity')), 'humanity')
const humanityDetails = r => require.ensure([], () => r(require('../page/humanity/details')), 'humanityDetails')
const video = r => require.ensure([], () => r(require('../page/video/video')), 'video')
const comment = r => require.ensure([], () => r(require('../page/point/comment')), 'comment')
const activity = r => require.ensure([], () => r(require('../page/activity/activity')), 'activity')
const activityDetails = r => require.ensure([], () => r(require('../page/activity/details')), 'activityDetails')
const routeDetails = r => require.ensure([], () => r(require('../page/route/details')), 'routeDetails')
const pointDetails = r => require.ensure([], () => r(require('../page/point/details')), 'pointDetails')
const charts = r => require.ensure([], () => r(require('../page/charts/charts')), 'charts')
const chartLine = r => require.ensure([], () => r(require('../page/charts/chartLine')), 'chartLine')

const msite = r => require.ensure([], () => r(require('../page/msite/msite')), 'msite')
const search = r => require.ensure([], () => r(require('../page/search/search')), 'search')
const profile = r => require.ensure([], () => r(require('../page/profile/profile')), 'profile')
const maps = r => require.ensure([], () => r(require('../page/maps/maps')), 'maps')
const answer = r => require.ensure([], () => r(require('../page/answer/answer')), 'answer')
const integral = r => require.ensure([], () => r(require('../page/profile/integral')), 'integral')
const webview = r => require.ensure([], () => r(require('../page/webview/webview')), 'webview')
const profileRoute = r => require.ensure([], () => r(require('../page/profile/route')), 'profileRoute')
const profileActivity = r => require.ensure([], () => r(require('../page/profile/activity')), 'profileActivity')
const profileComment = r => require.ensure([], () => r(require('../page/profile/comment')), 'profileComment')
const profilePoint = r => require.ensure([], () => r(require('../page/profile/point')), 'profilePoint')

const info = r => require.ensure([], () => r(require('../page/profile/children/info')), 'info')
const setusername = r => require.ensure([], () => r(require('../page/profile/children/children/setusername')), 'setusername')
const address = r => require.ensure([], () => r(require('../page/profile/children/children/address')), 'address')
const add = r => require.ensure([], () => r(require('../page/profile/children/children/children/add')), 'add')
const addDetail = r => require.ensure([], () => r(require('../page/profile/children/children/children/children/addDetail')), 'addDetail')
const forget = r => require.ensure([], () => r(require('../page/forget/forget')), 'forget')
const order = r => require.ensure([], () => r(require('../page/order/order')), 'order')
const orderDetail = r => require.ensure([], () => r(require('../page/order/children/orderDetail')), 'orderDetail')
const vipcard = r => require.ensure([], () => r(require('../page/vipcard/vipcard')), 'vipcard')
const service = r => require.ensure([], () => r(require('../page/service/service')), 'service')
const invoiceRecord = r => require.ensure([], () => r(require('../page/vipcard/children/invoiceRecord')), 'invoiceRecord')
const useCart = r => require.ensure([], () => r(require('../page/vipcard/children/useCart')), 'useCart')
const vipDescription = r => require.ensure([], () => r(require('../page/vipcard/children/vipDescription')), 'vipDescription')
const questionDetail = r => require.ensure([], () => r(require('../page/service/children/questionDetail')), 'questionDetail')

export default [{
    path: '/',
    component: App, //顶层路由，对应index.html
    children: [ //二级路由。对应App.vue
        //地址为空时跳转home页面
        {
            path: '',
            name: 'home',
            redirect: '/home'
        },
        //首页
        {
            path: '/home',
            name: 'home',
            component: home,
            meta: { keepAlive: true, tabbar: true, title: "黄浦区人文行走导学平台" },
        },
        //路线-列表页
        {
            path: '/route',
            name: 'route',
            component: route,
            meta: { keepAlive: true, tabbar: true, title: "黄浦区人文行走导学平台" },
        },
        {
            path: '/follow',
            name: "follow",
            component: follow,
            meta: { keepAlive: true, tabbar: true, title: "黄浦区人文行走导学平台" },
        },
        //活动-列表页
        {
            path: '/activity',
            component: activity,
            meta: { title: "活动预约" },
        },
        //点位-列表页
        {
            path: '/point',
            component: point,
            meta: { title: "黄浦区人文行走导学平台" },
        },
        //点位-列表页
        {
            path: '/walk',
            component: walk,
            meta: { title: "行走动态" },
        },
        //webview
        {
            path: '/webview',
            component: webview,
            // meta: { keepAlive: true },
        },
        // maps
        //点位-列表页
        {
            path: '/maps',
            component: maps,
            // meta: { keepAlive: true },
        },
        // maps
        //点位-答题页
        {
            path: '/answer',
            component: answer,
            // meta: { keepAlive: true },
        },
        //点位-列表页
        {
            path: '/walk/details',
            component: walkDetails,
            // meta: { keepAlive: true },
        },
        //人文黄浦
        {
            path: '/humanity',
            component: humanity,
            // meta: { keepAlive: true },
        },
        //人文黄浦
        {
            path: '/humanity/details',
            component: humanityDetails,
            // meta: { keepAlive: true },
        },
        //精彩视频
        {
            path: '/video',
            component: video,
            meta: { title: "精彩视频" },
        },
        //活动-详情页
        {
            path: '/activity/details',
            component: activityDetails,
            // meta: { keepAlive: true },
        },
        //路线-详情页
        {
            path: '/route/details',
            component: routeDetails,
            // meta: { keepAlive: true },
        },
        //路线-详情页
        {
            path: '/point/details',
            component: pointDetails,
            // meta: { keepAlive: true },
        },
        //点位-评论
        {
            path: '/point/comment',
            component: comment,
            meta: { title: "评论" },
            // meta: { keepAlive: true },
        },
        //路线-我的
        {
            path: 'profileRoute',
            component: profileRoute,
            meta: { title: "我的路线" },
        },
        // profileActivity
        //收藏-我的
        {
            path: 'profileActivity',
            component: profileActivity,
            meta: { title: "我的收藏" },
        },
        // profileComment
        {
            path: 'profileComment',
            component: profileComment,
            meta: { title: "我的评论" },
        },
        // profilePoint
        {
            path: 'profilePoint',
            component: profilePoint,
            meta: { title: "我的打卡" },
        },
        // integral
        {
            path: 'integral',
            component: integral,
            // meta: { keepAlive: true },
        },


        //所有商铺列表页
        {
            path: '/msite',
            component: msite,
            meta: { keepAlive: true },
        },
        //搜索页
        {
            path: '/search/:geohash',
            component: search
        },
        //个人信息页
        {
            path: '/profile',
            name: 'profile',
            component: profile,
            meta: { keepAlive: true, tabbar: true, title: "黄浦区人文行走导学平台" },
            children: [{
                path: 'info', //个人信息详情页
                component: info,
                meta: { title: "设置" },
                children: [{
                    path: 'setusername',
                    component: setusername,
                }, {
                    path: 'address',
                    component: address,     //编辑地址
                    children: [{
                        path: 'add',
                        component: add,
                        children: [{
                            path: 'addDetail',
                            component: addDetail
                        }]
                    }]
                }]
            },
            {
                path: 'service', //服务中心
                component: service,
            },]
        },
        //修改密码页
        {
            path: '/forget',
            component: forget
        },
        //订单列表页
        {
            path: '/order',
            component: order,
            children: [{
                path: 'orderDetail', //订单详情页
                component: orderDetail,
            },]
        },
        //vip卡页
        {
            path: '/vipcard',
            component: vipcard,
            children: [{
                path: 'invoiceRecord', //开发票
                component: invoiceRecord,
            }, {
                path: 'useCart', //购买会员卡
                component: useCart,
            }, {
                path: 'vipDescription', //会员说明
                component: vipDescription,
            },]
        },
        //服务中心
        {
            path: '/service',
            component: service,
            children: [{
                path: 'questionDetail', //订单详情页
                component: questionDetail,
            },]
        },
    ]
}]
