<template>
    <van-tabbar
        v-model="active"
        active-color="#B72E32"
        inactive-color="#231F20"
        @onChange="onChange"
        :route="true"
        :style="{
            'z-index:': 10000,
        }"
    >
        <van-tabbar-item
            name="home"
            replace
            to="/home"
            icon="http://oss.hkrin.com/userInfo/666/20211022114120home_x@3x.png"
            >首页</van-tabbar-item
        >
        <van-tabbar-item
            name="route"
            replace
            to="/route"
            icon="http://oss.hkrin.com/userInfo/666/20211022114135line_x@3x.png"
            >路线</van-tabbar-item
        >
        <van-tabbar-item
            name="profile"
            replace
            :to="isWeixin ? '/profile' : '/follow'"
            icon="http://oss.hkrin.com/userInfo/666/20211022114147wode_x@3x.png"
            >我的</van-tabbar-item
        >
    </van-tabbar>
</template>

<script>
export default {
    data() {
        return {
            active: "home",
            isWeixin: this.$isWeixin,
        };
    },
    created() {},
    mounted() {
        const path = sessionStorage.getItem("path");
        console.log(path);
        this.active = path || "home";
    },
    methods: {
        onChange(name) {
            console.log(name);
        },
    },
};
</script>

<style lang="scss">
.van-tabbar {
    height: 60px;
}
</style>
